var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _vm.user
        ? _c(
            "breadcrumb-header",
            {
              attrs: {
                labels: [_vm.user.name],
                go_back_to: "management_users",
              },
            },
            [
              !_vm.$store.getters.is_feature_enabled(
                _vm.SUPPORTED_FEATURES.GOOGLE_AUTH
              )
                ? [
                    _c(
                      "el-button-group",
                      [
                        _vm.tempPassword
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:copy",
                                    value: _vm.tempPassword,
                                    expression: "tempPassword",
                                    arg: "copy",
                                  },
                                ],
                                attrs: {
                                  readonly: "",
                                  size: "mini",
                                  icon: "el-icon-document-copy",
                                },
                                on: { click: _vm.showNotification },
                              },
                              [_vm._v(" " + _vm._s(_vm.tempPassword) + " ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title:
                                "This will reset the users password to a random password",
                            },
                            on: { confirm: _vm.resetTargetPassword },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  size: "mini",
                                  disabled: _vm.resetting || _vm.userIsMe,
                                },
                                slot: "reference",
                              },
                              [_vm._v(" Reset Password ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "inner" }, [
        _vm.user
          ? _c(
              "div",
              { staticClass: "list" },
              _vm._l(_vm.weeks, function (week) {
                return _c("hours-week", {
                  key: week.format("W[-week]"),
                  attrs: {
                    week: week,
                    sessions: _vm.sessions_grouped[week.isoWeek()],
                  },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }