var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "week" }, [
    _c("div", { staticClass: "week-header" }, [
      _c("span", { staticClass: "week-number" }, [
        _vm._v(" " + _vm._s(_vm._f("dateformat")(_vm.week, "[W]W")) + ": "),
      ]),
      _vm.days_with_sessions
        ? _c("div", [
            _c("label", [_vm._v("Average day:")]),
            _c(
              "span",
              [
                _vm._v(" " + _vm._s(_vm.average_start_time) + " "),
                _c("svgicon", {
                  staticClass: "arrow",
                  attrs: { name: "triangle" },
                }),
                _vm._v(" " + _vm._s(_vm.average_end_time) + " "),
              ],
              1
            ),
            _c("span", [_vm._v(_vm._s(_vm.average_diff_time) + "h")]),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "days" },
      _vm._l(_vm.data, function (day, idx) {
        return _c("hours-day", {
          key: day.date.unix(),
          attrs: {
            date: day.date,
            data: day,
            sessions: _vm.data[idx].sessions,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }