var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "day", class: { today: _vm.is_today } }, [
    _c("div", { staticClass: "day-header" }, [
      _c("span", { staticClass: "day-name" }, [
        _vm._v(" " + _vm._s(_vm._f("dateformat")(_vm.date, "dddd")) + " "),
      ]),
      _c("span", { staticClass: "day-number" }, [
        _vm._v(" " + _vm._s(_vm._f("dateformat")(_vm.date, "D")) + " "),
      ]),
    ]),
    _vm.has_sessions
      ? _c("div", { staticClass: "content" }, [
          _c("span", { staticClass: "quantity" }, [
            _vm._v(
              " " +
                _vm._s(_vm.sessions.length) +
                " " +
                _vm._s(_vm._f("pluralize")("session", _vm.sessions.length)) +
                " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "period" },
            [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("milli2date")(
                        _vm.first_session.start.seconds * 1000
                      )
                    ) +
                    " "
                ),
              ]),
              _c("svgicon", {
                staticClass: "arrow",
                attrs: { name: "triangle" },
              }),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("milli2date")(_vm.last_session.stop.seconds * 1000)
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "period" }, [
            _c(
              "span",
              {
                staticClass: "total-duration",
                class: { error: _vm.total_duration < 21600 },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("seconds2duration")(_vm.total_duration)) +
                    " "
                ),
              ]
            ),
            _c("span", { staticClass: "small" }, [_vm._v("h tracked")]),
          ]),
          _c(
            "ul",
            { staticClass: "breakdown" },
            _vm._l(Object.keys(_vm.projects_breakdown), function (project) {
              return _c("li", { key: project }, [
                _c("span", [_vm._v(_vm._s(project) + ":")]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("seconds2duration")(
                          _vm.projects_breakdown[project]
                        )
                      ) +
                      " "
                  ),
                ]),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }